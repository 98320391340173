import Div from 'components/Div';
import { getOrderContext } from 'contexts/order';
import { getReferenceContext } from 'contexts/reference';
import React from 'react';
import { NotificationManager } from 'react-notifications';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Req from 'utils/Request';
import * as Types from './types'

const Foodcart = () => {
    const navigate = useNavigate()
    const [data, setData] = React.useState(null)
    const { setIsLoading } = React.useContext(getReferenceContext)
    const [selectedTab, setSelectedTab] = React.useState('')
    const { address, custPhone } = React.useContext(getOrderContext)
    React.useEffect(() => {
      if (address) fetchData()
    }, [])
    React.useEffect(() => {
        if(!address) {
          if(custPhone) {
            navigate({ pathname: `/address/${custPhone}`, search: createSearchParams({rerouted: true}).toString()})
          }
        }
    }, [])
    const fetchData = async () => {
        try {
            setIsLoading(true)
            const { data: { data } } = await Req({url: '/menu/getMenus', body: {storeName: address.store ?? address.storeName}, hideSuccess: true})
            const items = ['Beverage', 'Combo', 'Dessert', 'Pizza', 'Side']
            // delete data.Others
            // delete data.Condiments
            // delete data.null
            // delete data.Deal
            // delete data.Sample
            // delete data.Meal
            Object.keys(data).map(item =>{
              const findItem = items.find(i=> i === item)
              if(!findItem) delete data[item]
            })
            if(!Object.keys(data).length) throw new Error('Menu data is empty')
            setData(data)
            setSelectedTab(Object.keys(data)[0])
        } catch (e) {
            NotificationManager.error(e.message, 'Error getMenus', 3000)
        } finally { setIsLoading(false) }
    }
    const render = () => {
        let ref
        if(selectedTab === "Pizza" || selectedTab === "Combo") ref = selectedTab
        else ref = "Others"
        const SelectedComponent = Types[ref]
        return <SelectedComponent type={selectedTab} data={data[selectedTab]}/>
    }
    if (data) return (
        <Container>
            <Div c="tab_wraper">
                {data ? Object.keys(data).map(Type => (
                  Type !== 'Emp.Meal' &&
                    <Div
                        c="tab"
                        open={selectedTab === Type}
                        key={Type}
                        onClick={() => setSelectedTab(Type)}
                    >
                        <img src={`/images/menu-${Type.toLowerCase()}.png`}/>
                        <span>{Type}</span>
                    </Div>
                )) : ''}
            </Div>

            <Div c="tab_content">
                {selectedTab && render()}
            </Div>
        </Container>
    );
};

export default Foodcart;

const Container = styled.div`
  .tab_wraper {
    display: flex;
    .tab {
      padding: 15px 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-right: none;
      font-size: 14px;
      cursor: pointer;
      display:flex;
      align-items:center;
      gap:10px;
      img{
        width:30px;
        height:30px;
        object-fit:contain;
      }
      &:last-child {
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      &[open] {
        color: ${({ theme }) => theme.color};
        font-weight: bold;
        border-bottom: 1px solid white;
        cursor: default;
      }
    }
  }
  .tab_content {
    margin-top: -1px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 15px;
  }
`;
