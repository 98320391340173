import Button from 'components/Button';
import Div from 'components/Div';
import Form from 'components/Form';
import Title from 'components/Title';
import React from 'react';
import styled from 'styled-components';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlinePlus, AiOutlineSave, AiOutlineSearch } from 'react-icons/ai';
import axios from 'axios';
import { getReferenceContext } from 'contexts/reference';
import Table from 'components/Table';
import { tableEnquiry } from 'utils/tableModels';
import { NotificationManager } from 'react-notifications';
import Dialog from 'components/Dialog';
import { popupFormStyle } from 'utils/sharedStyles';
import { formCreateEnquiry } from 'utils/formModels';
import EnquiryCategory from './EnquiryCategory';
import Tag from 'components/Tag';
import RichtextParser from 'components/RichtextParser';
import { getOrderContext } from 'contexts/order';
import Req from 'utils/Request';
import { useNavigate } from 'react-router-dom';
import { getAuthContext } from 'contexts/auth';
import { timeSince } from 'utils/helpers';

const Enquiry = ({ onlyTable }) => {
    const { roles, user } = React.useContext(getAuthContext);
    const { setIsLoading } = React.useContext(getReferenceContext);
    const [data, setData] = React.useState(null);
    const [enquiry, setEnquiry] = React.useState({});
    const [manager, setManager] = React.useState(null);
    const [solved, setSolved] = React.useState(false);
    const [filters, setFilters] = React.useState({});
    const [categoryObject, setCategoryObject] = React.useState(null);
    const [showCreate, setShowCreate] = React.useState(false);
    const [createEnquiryInstance, setCreateEnquiryInstance] = React.useState({});
    const [newCategoryObject, setNewCategoryObject] = React.useState(null);
    const [deleteEnquiry, setDeleteEnquiry] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const thisForm = React.useRef();
    const navigate = useNavigate();
    let result;
    const { custPhone } = React.useContext(getOrderContext);

    const fetchData = async () => {
        const body = {
            id: categoryObject.childCategoryId ?? categoryObject.categoryId,
        };
        const result = await Req({
            url: '/enquiry/category/findById',
            body,
            hideSuccess: true,
        });
        setData(result.data.data.enquiryCategory);
    };
    const getUserEnguiry = async () => {
        const body = {
            custPhone,
        };
        const result = await Req({
            url: '/enquiry/list',
            body,
            hideSuccess: true,
        });
        setEnquiry(result.data.data.enquiry);
    };

    React.useEffect(() => {
        setSolved(false);
        if (custPhone) {
            getUserEnguiry();
        }
    }, []);

    React.useEffect(() => {
        const filtered = roles.filter((x) => x === 'INFO');
        if (filtered[0]) setManager(filtered[0]);
    }, [roles]);

    React.useEffect(() => {
        if (categoryObject) {
            setEditMode(false);
            fetchData();
        }
        if (custPhone) {
            getUserEnguiry();
        }
    }, [categoryObject]);

    const handleCreate = async (e) => {
        e?.preventDefault();
        if (!newCategoryObject) {
            NotificationManager.warning('Категориудаас сонголт хийгээгүй байна', '', 1000);
        } else {
            const body = {
                ...createEnquiryInstance,
                parentId: newCategoryObject.childCategoryId ?? newCategoryObject.categoryId,
                custPhone,
            };
            await Req({ url: '/enquiry/category/create', body });
        }
    };
    const handleEdit = async () => {
        const body = { note: data.note, id: data.id, name: data.name };
        await Req({ url: '/enquiry/category/update', body });
        setEditMode(false);
        fetchData();
    };
    const handleCheck = (e) => {
        setSolved(true);
    };
    const handleSave = async () => {
        const body = {
            category: categoryObject.category ? categoryObject.category : '',
            childCategory: categoryObject.childCategory ? categoryObject.childCategory : '',
            isSolved: solved,
            custPhone,
            operator: user.username,
        };
        await Req({ url: '/enquiry/resolve', body });
    };
    const handleDelete = async () => {
        if (!deleteEnquiry) return NotificationManager.warning('Устгах лавлагаа сонгоно уу!', '', 3000);
        const body = {
            id: deleteEnquiry.id,
        };
        const result = await Req({ url: '/enquiry/category/delete', body });
        if (result.code === 200 && result.success) {
            return NotificationManager.success('Амжилттай хадгаллаа!', '', 3000);
        }
        setDeleteEnquiry(null);
        fetchData();
    };

    return (
        <Container>
            <Title size={20}>Лавлагаа</Title>
            <Div c="outer_wrap">
                {!onlyTable && (
                    <Div c="top_wrap">
                        {manager && (
                            <>
                                <EnquiryCategory onChange={setCategoryObject} />
                                <Div c="main_wrap">
                                    <Div c="add_wrap">
                                        <Button onClick={() => setShowCreate(true)} bg="green" color="white">
                                            <AiOutlinePlus />
                                            Лавлагаа үүсгэх
                                        </Button>
                                    </Div>

                                    {data && (
                                        <Div c="content_wrap">
                                            <Title btmLine size={20}>
                                                {data?.category}
                                            </Title>
                                            {editMode ? (
                                                <>
                                                    <Form model={formCreateEnquiry} instance={data} listenChange={setData} />
                                                </>
                                            ) : (
                                                <RichtextParser data={data.note} />
                                            )}
                                            <Div c="btn_wrap">
                                                <Button onClick={() => (editMode ? handleEdit() : setEditMode(true))} bg="green" color="white">
                                                    {editMode ? <AiOutlineSave /> : <AiOutlineEdit />}
                                                    {editMode ? 'Хадгалах' : 'Засах'}
                                                </Button>
                                                <Button
                                                    disabled={!data.parentId}
                                                    bg="color"
                                                    onClick={() => {
                                                        setDeleteEnquiry({
                                                            id: data.id,
                                                        });
                                                    }}
                                                >
                                                    Устгах
                                                </Button>
                                            </Div>
                                        </Div>
                                    )}
                                </Div>
                            </>
                        )}
                        {!manager && (
                            <>
                                <EnquiryCategory onChange={setCategoryObject} />
                                <Div c="main_wrap">
                                    <Div c="add_wrap">
                                        <input type="checkbox" value="true" onChange={handleCheck} id="isSolved" />
                                        <label htmlFor="isSolved">Үйлчлүүлэгч мэдээллээ бүрэн авсан эсэх?</label>
                                        {categoryObject && (
                                            <Button onClick={() => handleSave()} bg="green" color="white">
                                                <AiOutlinePlus />
                                                Хадгалах
                                            </Button>
                                        )}
                                    </Div>
                                    {data && (
                                        <Div c="content_wrap">
                                            <Title btmLine size={20}>
                                                {categoryObject?.category}
                                            </Title>

                                            <RichtextParser data={data.note} />
                                        </Div>
                                    )}
                                </Div>
                            </>
                        )}
                    </Div>
                )}

                <Div c="bottom_wrap">
                    {!onlyTable && <Title>Өмнөх лавлагаа:</Title>}
                    <Table
                        data={enquiry}
                        model={{
                            custom2: {
                                label: 'Огноо',
                                jsx: ({ thisInstance }) => <Tag color="green">{timeSince(thisInstance.created_at)}-ийн өмнө</Tag>,
                            },
                            ...tableEnquiry,
                        }}
                    />
                </Div>
            </Div>
            {manager && showCreate && (
                <Dialog
                    onClose={() => setShowCreate(false)}
                    title="Лавлагаа үүсгэх"
                    submit={
                        <Button onClick={() => thisForm.current.querySelector('.submit').click()} bg="blue" type="submit">
                            Хадгалах
                        </Button>
                    }
                    // width="500px"
                >
                    <Div c="create_enquiry_wrap">
                        <EnquiryCategory onChange={setNewCategoryObject} />
                        <Form forwardRef={thisForm} model={formCreateEnquiry} instance={createEnquiryInstance} listenChange={setCreateEnquiryInstance} onSubmit={handleCreate} />
                    </Div>
                </Dialog>
            )}
            {deleteEnquiry && (
                <Dialog
                    onClose={() => setDeleteEnquiry(null)}
                    title={'Энэ лавлагааг устгахдаа итгэлтэй байна уу?'}
                    submit={
                        <Button onClick={() => handleDelete()} bg="green">
                            Тийм
                        </Button>
                    }
                >
                    <Container>
                        <Form instance={deleteEnquiry} listenChange={setDeleteEnquiry} onSubmit={handleDelete} forwardRef={thisForm} model={{}} />
                    </Container>
                </Dialog>
            )}
        </Container>
    );
};

export default Enquiry;

const Container = styled.div`
    .outer_wrap {
        display: flex;
        flex-direction: column;
        .top_wrap {
            display: flex;
            gap: 30px;
            .main_wrap {
                width: 70%;
                .add_wrap {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                .content_wrap {
                    .btn_wrap {
                        margin-top: 15px;
                        display: flex;
                        gap: 10px;
                        justify-content: flex-end;
                    }
                }
            }
        }
        .bottom_wrap {
            margin-top: 30px;
            width: 100%;
            height: auto;
        }
    }
    .create_enquiry_wrap {
        display: flex;
        form {
            & > div {
                margin-bottom: 15px;
                & > * {
                    &:first-child {
                        display: block;
                        margin-bottom: 6px;
                    }
                    &:last-child {
                    }
                }
            }
        }
    }
`;
